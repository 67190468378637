<script setup lang="ts">
import type {
  Invoice,
  InvoiceList,
  InvoicePayment,
  InvoiceRelatedPayments,
} from '~/types/invoices'
import type { InvoiceType } from '~/types/misc'
import { invoiceOptions } from '~/types/misc'
import { badgeTypes } from '~/utils/invoices'

defineProps<{
  showPatientName?: boolean
  item: InvoiceList | InvoiceRelatedPayments | Invoice | InvoicePayment
}>()

const nuxtApp = useNuxtApp()
</script>

<template>
  <div
    class="grid grid-cols-3 grid-rows-2 items-center gap-y-2 rounded-lg p-2 transition-colors duration-300 hover:bg-sky-50"
  >
    <div class="col-span-2 mr-3 flex items-center text-sm text-gray-500">
      {{ nuxtApp.$dayjs(item.created_at).format('DD/MM/YYYY') }}
    </div>

    <div class="flex items-center justify-end">
      <UBadge
        v-if="badgeTypes[item.type as InvoiceType]"
        :color="badgeTypes[item.type as InvoiceType].color"
        :label="badgeTypes[item.type as InvoiceType].label"
      />
    </div>

    <div class="col-span-2 flex items-center">
      <UAvatar
        v-if="showPatientName"
        class="mr-3"
        :src="(item as InvoiceList).patient_profile_picture ?? ''"
        :alt="(item as InvoiceList).patient_name ?? ''"
        size="sm"
        loading="lazy"
      />
      <h3 class="truncate text-sm font-medium text-gray-900">
        <span v-if="showPatientName">{{
          (item as InvoiceList).patient_name
        }}</span>
        <span v-else>
          {{ invoiceOptions[item.type - 1].acronym }}
          {{ item.series_prefix }}/{{ item.number }}
        </span>
      </h3>
    </div>

    <div
      class="flex items-center justify-end text-sm font-medium text-gray-700"
    >
      <p>
        {{
          formatCurrency(
            (item as InvoiceList | InvoiceRelatedPayments | InvoicePayment)
              .amount ?? (item as Invoice).total,
          )
        }}
      </p>
    </div>
  </div>
</template>
